.topbar{
    position: relative;
    width: 100vw;
    height: auto;

}
.topbar__container{
    position: absolute;
    left: 15rem;
    border: 1px solid #c5c5c554;
    box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
    -webkit-box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
    -moz-box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
    width: 82%;
    overflow: hidden;
    height: 50px;
    padding: 20px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    /* border: 1px solid red; */
}
.bar{
    /* display: none; */
    color: #fff;
}
.topbar__content{
    display: flex !important;
    justify-content: center !important;
    align-items: end !important;
    /* border: 1px solid #444; */
    height:5vh;
}
.topbar__content .topbar__name{
    color: #777;
    margin-right: 1rem;
    font-weight: 600;

}
.topbar__user{
    width: 20px;
    height: 20px;
    /* border: 1px solid #777; */
}
.top__img{
    width:1.77rem;
    height:1.7rem;
    object-fit: cover;
    border: 1px solid #777;
    border-radius: 50%;
    cursor: pointer;
}

.btn__notification{
    border: none;
    background-color: #ffff;
    margin-right: 2rem;
}
.bel_icon, .messages__icon{
    font-size: 1.3rem;
    color: #999 !important;
}
.badge{
    font-size: .55rem !important;
}
/* responsive styles */
  
@media only screen and (max-width:468px){
    .topbar{
        width: 100%;
        position: relative;
    }
    .bar{
        display: block;
        cursor: pointer;
    }
    .bar__icon{
        color: #666;
        font-size: 1.4rem;
        color:#ffa400;
        transition: all 0.5s ease-in-out;
    }
    .topbar__container{
        display: flex;
        width: 100%;
        left: 0;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        z-index: -1; 
    }
   
}