.testimonial-section{
  background-color: #ebebeb49;
}
.title{
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height:60px ;
    font-size: 30px !important;
    color: #555;
  }
  
  .title::after{
    content: " ";
    background: #ffa400;
    width: 80px;
    height: 5px; 
    position: absolute; 
    bottom: 0;
    left: 50%; 
    border-radius:30px 20px 10px 20px;
    transform: translateX(-50%);
  }
  .top-description{
    max-width:700px;
    margin: auto;
    font-family: serif;
    color: #777;
    font-size: 17px;
  }
  .testimonial-section {
      padding-top: 30px;
      padding-bottom: 30px;
      overflow-x: hidden;
    }
    
    .testimonial-container h4,
    h3 {
      text-align: center;
    }
    
    #customer-testimonial {
      margin-top: 6%;
    }
    
    #customer-testimonial .item {
      text-align: center;
      padding: 50px;
      margin-bottom: 0px;
      opacity: 0.2;
      transform: scale3d(0.8, 0.8, 1);
      transition: all 0.3s ease-in-out; 
      max-width: 550px;
      height: 100%;
    }
    
    #customer-testimonial .owl-item.active.center .item {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    
    .owl-slide .owl-dots .owl-dot.active span,
    .owl-slide .owl-dots .owl-dot:hover span {
      background: #56423d;
      transform: translate3d(0px, -50%, 0px) scale(0.8);
    }
    
    .owl-carousel .owl-dots {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
    
    .owl-carousel .owl-dots .owl-dot {
      display: inline-block;
    }
    
    .owl-carousel .owl-dots .owl-dot span {
      background: #56423d;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 2px 5px;
      transform: translate3d(0px, -50%, 0px) scale(0.3);
      transform-origin: 50% 50% 0;
      transition: all 250ms ease-out 0s;
    }
    