.services{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 100%;
}
.swiper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.swiper-button-prev, .swiper-button-next{
    color: #b9b9b9a1 !important;
}
.swiper-button-next:hover{
    color: #febb02 !important;
}
.swiper-button-prev:hover{
    color: #febb02 !important;
}
.slide-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
    -webkit-box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
    -moz-box-shadow: 2px 4px 6px 2px rgba(222,222,222,0.75);
          transition: all 0.5s ease-in-out;
}
.slide-img:hover{
    transform: scale(1.1);
}
.one:hover{
    box-shadow: 0px 0px 6px 2px #61ff6bbf;
    -webkit-box-shadow: 0px 0px 6px 2px rgba(97,255,107,0.75);
    -moz-box-shadow: 0px 0px 6px 2px rgba(97,255,107,0.75);
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
}
.one:hover .txt{
    background-color: #fcb126cb;
    color: #fff;
}
.img-container{
    position: relative;
}
.txt{
    position: absolute;
    left: 0;
    bottom: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    color: #270f0fdc;
    background-color: #dbc4c465;
    transition: all 0.5s ease-in-out;
}
.txt .spa{
    color: #febb02;
    font-size: 1.43rem;
}
.txt .ser-para{
    text-align: center;
}

/* responsive styles*/

@media only screen and (max-width: 468px){
    .txt{
        position: absolute;
        left: 0;
        bottom: 30px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 15px;
        color: #270f0fdc;
        background-color: #dbc4c465;
        transition: all 0.5s ease-in-out;
    }
}