
.shadow-effect {
    background: #fff;
    text-align: justify;
    border-radius: 10px !important;
    padding: 10px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 15px 12px rgba(0, 0, 0, 0.02);
    height: 250px;
}

.sp-btn {
    border: none;
    padding: 0px 3px;
    margin: 3px;
    font-size: 10px;
}
.Sreviewsvg__container{
    width: 100%;
    height: 50px;
}
.Sreview__svg {
    border-radius: 10px;
    position: absolute;
    bottom: 2.78rem;
    right: 3.1rem;
    width: 73%;
    height: auto;
}

.Singlerev__img {
    width: 6rem;
}

.top-btn-one {
    background-color: tomato;
    color: #fff;
}

.Sreview__texts {
    color: #000000cc;
    font-size: .68rem;
    height: auto;
}

.top-btn-two {
    background-color: #47dd19;
    color: #fff;
}

.top-text {
    color: #346aff;
    font-size: 13px;
}

.fas {
    font-size: 12px;
    color: #ffa400;
    margin-left: 4px;
}

.Fa {
    color: gray;
}

.feedback {
    font-size: 12px;
    color: gray;
    margin-left: 5px;
}

.providers {
    color: #346aff;
    font-weight: bold;
    font-size: 17px;
}

.courtesy {
    color: gray;
    font-size: 12px;
}

.sheba {
    color: #346aff;
    font-weight: 600;
}

.location {
    color: gray;
    font-weight: 600;
    font-size: 14px;
}

.shadow-effect p {
    font-family: inherit;
    font-size: 14px;
    /* line-height: 1.5; */
    margin: 0 0 17px 0;
    font-weight: 300;
}

.top-circle {
    border-radius: 50%;
    /* vertical-align: baseline; */
    max-width: 50px;
    min-height: 50px;
    border: 3px solid #fff;
    transform-style: preserve-3d;
    /* margin: -14px 3px 8px; */
}

.top-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    background: linear-gradient(100deg, #845EC2, #BE93FD);
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 468px){
    .Sreview__svg {
        border-radius: 10px;
        position: absolute;
        bottom: 2.78rem;
        right: 3.1rem;
        width: 77%;
        height: auto;
    }
}