.single{
    position: absolute;
    left: 16rem;
    top: 4rem;
    width: 80%;
    height: auto;
    padding: 1rem;
    border: 1px solid #444;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
    border: 1px solid #c5c5c554;
    box-shadow: 2px 4px 6px 2px #dededebf;
    -webkit-box-shadow: 2px 4px 6px 2px rgba(241, 241, 241, 0.75);
    -moz-box-shadow: 2px 4px 6px 2px #dededebf;
}
.Single__form{
    border: 0.99px  #b4b1b391;
    width: 77vw;
    height:auto;
    margin-top: 2rem;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 1px 1px 8px 0px rgba(232,221,224,0.75);
-webkit-box-shadow: 1px 1px 8px 0px rgba(232,221,224,0.75);
-moz-box-shadow: 1px 1px 8px 0px rgba(232,221,224,0.75);
}
.starTitle{
    font-weight: 600;
    font-size: 1.23rem;
    color: #000000cb;
    letter-spacing: 0.5px;
}
.single__box .singleItem__data{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.single__imgs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
}
.s__img{
    margin: .5rem;
    width: 15rem;
    height: 10rem;
    object-fit: cover;
}
.single__text{
    font-weight: 500;
    color: #888;
}
.chepPrice{
    width: 60%;
}
.s__price{
    width: 30%;
}
.hotel__desc{
    width: 60%;
    height: 100%;

}