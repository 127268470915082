.Gototop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.Gototop .topBtn__container{
    font-size: 1rem;
    width: 3rem;
    height: 3rem;
    background-color: #ffa400;
    color: #fff;
    border-radius: 50%;
    position: fixed;
    bottom: 1rem;
    right: 5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 8px 12px 21px -3px rgba(0,0,0,0.28);
    -webkit-box-shadow: 2px 6px 9px 1px #acacac47;
    -moz-box-shadow: 8px 12px 11px -3px rgba(0,0,0,0.28);
    border-radius: 0px 80px 67px 67px;
}
.arrow__up{
    animation: gototop 1.2s linear infinite alternate-reverse;
}
@keyframes gototop {
    0% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(0.8rem);
    }
  }

@media only screen and (max-width: 468px){
    .topBtn__container{
        right: 0;
        /* left: 40%; */
      }
}