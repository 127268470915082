.pList{
    width: 100%;
    /* max-width: 1024px; */
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .pListItem{
    flex:1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: all .4s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .pListItem:hover .pListImg{
      transform: scale(1.1);
      transition: all .4s ease-in-out;
  }
  .pListImg{
    width: 100%;
    height: 150px;
    object-fit: cover;
    transition: all .9s ease-in-out;
  }
  .pListTitles{
    position: absolute;
    font-size: 10px;
    color: #313030;
    bottom: 20px;
    left: 20px;
    background-color: #b9b9b983;
    width: 80% ;
    border-radius: 10px;
  }


/* responsive styles */
@media only screen and (max-width: 768px){
    .pList{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
}