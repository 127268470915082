.ar{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: #f3f3f32c;
}
.ar__container{
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.colum:nth-child(2){
    transform: scale(1.1);
}
.card {
    position: relative;
    overflow: hidden;
    width: 95%;
    height: 90%;
    border-radius: 15px;
    /* border: 1px solid #ffffff41; */
    margin: 1rem;
    background: #f3f3f34f;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff; 
                transition: all 0.5s ease-in-out
}
.card:hover{
    transform: translateY(15px);
}
.colum{
    transition: all 0.1s ease-in-out;
}
.colum:hover .card__overlay{
    background-image: linear-gradient(rgba(0,0,0,0.03) 4%,#000000 55%);
    transition: all 0.1s ease-in-out;
}
.card .img__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.card__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}
.img__container:hover .card__img{
    transform: scale(1.2);
    transition: all 0.3s ease;
}
.card__overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    background-image: linear-gradient(-180deg,rgba(0,0,0,0.00) 4%,#000 105%);
}
.card-title{
    color: #fff;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}
.card__text{
    color: #fff;
    height: 65px;
    font-size: .88rem;
}

@media only screen and (max-width: 468px){
    .a{
        color: #0d6efd;
        text-decoration: underline;
        display: flex;
        width: 100%;
        height: 100%;
    }
    .ar{
        color: #0d6efd;
        text-decoration: underline;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .colum{
        margin-bottom: 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .colum:nth-child(2){
        transform: scale(1.0);
    }
    .card{
        width: 100%;
        height: 100%;
     
    }
    .card .img__container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .card__img{
        object-fit: cover;
    }
}