.r{
  padding: 40px;
  background-color: #5e687949;
}
.reserve {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5e687949;
  }
  
  .rContainer {
    background-color: #0071c2;
    box-shadow: -3px -3px 7px #ffffff73,
    2px 2px 5px #5e68798c;
    padding: 40px;
    color: #fff;
    border-radius:10px;
    position: relative;
    
  }
  
  .rClose {
    position: absolute;
    top: 40px;
    right: 50px;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
    color: #ffa400;
  }
  
  .rItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding: 10px;
  }
  
  .rInfo {
    display: flex;
    flex-direction: column;
  }
  
  .rTitle {
    font-weight: 500;
  }
  
  .rDesc {
    font-weight: 300;
  }
  
  .rMax {
    font-size: 12px;
  }
  
  .rPrice {
    font-weight: 500;
  }
  .rlabel{
    color: #fff;
  }
  .slroom{
    margin-left: .62rem;
    color: #f3b440;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .rSelectRooms{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 8px;
    color: gray;
  }
  
  .room{
    display: flex;
    flex-direction: column;
  }
  
  .rButton {
    border: none;
    padding: 10px 20px;
    background-color: #ffa400;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
  }
  