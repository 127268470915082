  .contain {
    background-color: #eeeeeeaf;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    margin-top: 5rem;
    border-right: 1px solid #bebebe81;
    border-radius: 30px;
    /* box-shadow:  10px 10px 70px #bebebe81,
    -10px -10px 20px #ffffff;  */
    transition: all 0.5s ease-in-out
  }
  
  div.form {
    background-color: #eee;
  }
  .contact-wrapper {
    margin: auto 0;
  }
  
  .submit-btn {
    float: left;
  }
  .reset-btn {
    float: right;
  }
  
  .form-headline:after {
    content: "";
    display: block;
    width: 30%;
    /* padding-top: 10px; */
    border-bottom: 3px solid #ffa400;
  }
  
  .highlight-text {
    color: #ffa400;
  }
  
  .hightlight-contact-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
  }
  
  .highlight-text-grey {
    font-weight: 500;
  }
  
  .email-info {
      margin-top: 20px;
      color: #212d31da;
      letter-spacing: 1px;
  }
  
  ::-webkit-input-placeholder { /* Chrome */
    font-family: 'Roboto', sans-serif;
    font-size: .77rem;
    margin: 3rem;
  }
  
  .required-input {
    color: black;
  }
  @media (min-width: 600px) {
    .contain {
      padding: 0;
    }
  }
  

  .form-input:focus,
  textarea:focus{
    outline: 1.5px solid #ec1c24;
  }
  
  .form-input,
  textarea {
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }
  
  .wrapper > * {
    padding: 1em;
  }
  @media (min-width: 700px) {
    .wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    .wrapper > * {
      padding: 2em 2em;
    }
  }
  
  /* ul {
    list-style: none;
    padding: 0;
  } */
  
  .contacts {
    color: #212d31;
  }
  
  .form {
    background: #fff;
  }
  
  /* form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  form label {
    display: block;
  }
  form p {
    margin: 0;
  } */
  
  .full-width {
    grid-column: 1 / 3;
  }
  