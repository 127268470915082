.mail__container{
  width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.mail{
    width: 100%;
    height: 300px;
    background-color: #ffa400;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px;
    border: none;
  }
  .mailInputContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mailInputContainer>input{
    width: 300px;
    height: 50px;
    padding: 10px;
    border: none;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .mailInputContainer .btn__subcribe{
    height: 50px;
    background-color: #fc2929;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 1rem;
    font-weight: bold;
    transition: all 0.5s ease-in-out;
  }
  .btn__subcribe:hover{
      background-color: #fff;
      color: #ff2424;
  }
  .mailTitle-mob{
    display: none;
  }

  @media only screen and (max-width: 468px){
    .mailTitle{
      display: none;
    }
    .mailTitle-mob{
      display: block;
    }
  
    .mailInputContainer>input{
      width: 200px;
      height: 50px;
      padding: 10px;
      border: none;
      margin-right: 10px;
      border-radius: 5px;
    }
    .mailInputContainer .btn__subcribe{
      height: 50px;
      background-color: #fc2929;
      color: white;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      padding: 1rem;
      font-weight: bold;
      transition: all 0.5s ease-in-out;
    }
  }