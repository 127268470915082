.Suser__data{
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    border: 1px solid #e0e0e041;
    height: 230px;
  
    overflow: hidden;
}
.Sleft__user{
    display: flex;
    flex-direction: column;
    margin: 2rem;
}
.single__img{
    width: 12rem;
    height: 11rem;
    object-fit: cover;
}
.showpass, .hidepass{
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.hidepass:hover{
    background-color:#ffa400 ;
    color: #fff;
    border-radius:10px;
    padding: 3px;
    font-size: .7rem;
} 
.showpass:hover{
    background-color:#ffa400 ;
    color: #fff;
    border-radius:10px;
    padding: 3px;
}
.Suser__cl{
    color: #888;
    font-weight: 500;
}
.single__pass{
    width: 400px;
}