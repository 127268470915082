.btn__add{
    border: none;
    background-color: #ffb222;
    padding: 7px 1rem;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    transition: all 0.5s ease;
}
.btn__add:hover{
    background-color: #f3a108ec;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
}