.featured{
    width: 100%;
    /* max-width: 1024px; */
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
  }
  
  .featuredItem{
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    flex:1;
    transition: all 0.6s ease;
  }
  .featuredItem:hover .featuredImg{
    transform:scale(1.2)
  }
  
  .featuredImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.6s ease;
  }
  
  .featuredTitles{
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #b9b9b983;
    width: 80% ;
    border-radius: 10px;
  }



  /* responsive styles */
  @media only screen and (max-width:468px) {
      .featured{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
  }