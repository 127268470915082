.fProperty{
  width: 100%;
  height: 100%;
  background-color: #faf6f6;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.fproperty__container{
  width: 92%;
  height: 100%;
  border-radius: 20px;
  background: #f3f3f34f;
  box-shadow:  20px 20px 60px #bebebe,
              -20px -20px 60px #ffffff; 
              transition: all 0.5s ease-in-out
}
.fproperty__container:hover{
  transform: translateY(15px);
  border-radius: 20px 20px 10px 10px;
  box-shadow: 10px 20px 60px #bebebe,
  -20px -20px 60px #ffffff;
  transition: all 0.5s ease-in-out
}
.fpropertyImg__container{
  width: 100%;
  height: 15rem;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
}
.fpImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 10px 0px;
}
.big{
  margin-bottom: 2rem;
}
.big:nth-child(2){
  transform: scale(1.1);
}
.fproperty__body{
  padding: .88rem;
}
.fproperty__date, .clndr{
  color: #888;
  font-size: .58rem;
  letter-spacing: 1px;
}
.fproperty__data{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  /* border: 1px solid #444; */
}
.fp__namerating{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}
.rating{
  margin-left: .66rem;
  font-weight: 600;
}
.star{
  color: #ffbb00;
  margin-left: .2em;
}
.fp__name{
  font-size: 1.4rem;
  color: #333;
  font-weight: 500;
  letter-spacing: 1px;
}
.fp__title {
  color: #999;
  font-size: .77rem;
}
.fp__type{
  margin-top: 5px;
}
.fp__address, .fp__city, .fp__type{
  color: #999;
  font-size: .88rem;
  margin-top: 2px;
}
.icon{
  font-size: .77rem;
  margin-right: .24rem;
}
.line{
  color: #999 !important;
}

@media only screen and (max-width: 468px){
  .fProperty{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fproperty__container{
    width: 300px;
    height: 400px;

  }
  .fpropertyImg__container{
    width: 300px;
    height: 200px;
  }
  .big{
    margin: 30px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fp__name{
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .fp__title {
    color: #999;
    font-size: .66rem;
  }
  .fp__address, .fp__city, .fp__type{
    color: #999;
    font-size: .66rem;
    margin-top: 2px;
  }
  .icon{
    font-size: .66rem;
    margin-right: .24rem;
  }
}