.hotelDetails{
    background-color: #FFFFFF;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: start;
}
.hdContainer{
    width: 100%;
}
.col-left{
    width: 100%;
    max-width: 524px;
}

.col-left .hd-title{
    font-weight: 700;
    color: #1D1D1D;
    /* color: #000000cb; */
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.col-left .hd-title-two{
    color: #000000cb;
    font-size: 1em;
    font-weight: 600;

}
.col-left .hd-para{
    /* color: #a09f9fe3; */
    color: #000000cb;
    font-size: 1em;
    margin-top: 30px;
}
.col-left .btn-hd-details{
    border: none;
    background-color: #ffa400;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    padding: 7px 20px;
    transition: all 0.5s ease-in-out;
}
.hd-icon{
    font-size: 12px;
    font-weight: 600;
    transition: all 0.5s ease-in-out;
    transform:rotate(-90deg);
    margin-left:5px;
}
.btn-hd-details:hover .hd-icon{
    transform:rotate(360deg)
}
/* col right */
.col-right{
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.col-right .titl-container{
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.col-right-img{
    width: 25rem;
    height:12.44rem;
    border-radius: 5px;
}

.hd-box{
    display: flex;
    position: relative;
}
.col-right-img-two{
    width: 25rem;
    height:12.44rem;
    position:absolute;
    top: 100px;
    left: 100px;
    border-radius: 5px;
    box-shadow: 2px 5px 7px 2px rgba(238,238,239,0.75);
    -webkit-box-shadow: 2px 5px 7px 2px rgba(238,238,239,0.75);
    -moz-box-shadow: 2px 5px 7px 2px rgba(238,238,239,0.75);
}

@media only screen and (max-width: 468px){
    .hotelDetails{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
    }
    .col-right{
        display: none;
    }
    .col-right .col-right-img-two{
        display: none;
    }
}