.login {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image:linear-gradient( #00000062, #302f2f67 ), url('../../img/room.jpg');
    background-repeat: no-repeat;
    background-size: cover;

  }
  
  .lContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    width: 300px;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: -3px -3px 7px #ffffff41,
              2px 2px 5px #5e687949;
  }
  .form{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lInput{
    height: 30px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .lButton {
    border: 1px solid #ffa400;
    padding: 5px 20px;
    background-color: #ffb52b;
    /* background-color: #0071c2; */
    color: #ffffff;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.5s ease;
  }
  .lButton:hover{
    border: 1px solid #ffa400;
    background-color: #fff;
    color: #ffa400;
  }
  .lButton:disabled{
    background-color: #0071c28c;
    cursor: not-allowed;
  }

.forgot .stext{
  color: #333;
  font-size: .86rem;
}