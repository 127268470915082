.footer{
  background-color: #222222;
  /* height: 100vh; */
  /* padding: 3.77rem; */
  overflow: hidden;
}
.fottCol-left{
  margin-top: 2rem;
}
 .fott-left-title{
  font-weight: 500;
  color: #ffffffe1;
  letter-spacing: 1px;
}
.fott-left-para{
    color: #ADADAD;
    font-size: .90rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.fottCol-left .fottbtn{
  border: none;
  background: #282828;
  color: #ADADAD;
  padding: 10px 35px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}
.fottCol-left .fottbtn:hover{
  background-color: #ffa400;
  color: #000;
  font-weight: 600;
  box-shadow: 0px 1px 5px 2px rgba(255,164,0,0.75);
  -webkit-box-shadow: 0px 1px 5px 2px rgba(255,164,0,0.75);
  -moz-box-shadow: 0px 1px 5px 2px rgba(255,164,0,0.75);

}
.fottCol-left .fottbtn:hover .language{
  transform: rotate(360deg);
  color: #000;
  font-weight: 600;
}
.fottCol-left .language{
  color: #ADADAD;
  margin-left: 1rem;
}

/* right side */
.rightContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}
.rightContainer .explore{
  color: #ADADAD;
  min-width: 15.15rem;
  min-height: 25.14rem
  /* border: 1px solid blue; */
}
.explore .ul{
  list-style-type:none;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  margin-left: -2rem;
  margin-top: 1rem;
}
.explore .li{
  transition: all 0.5s ease-in-out;
}
.explore .li:hover{
  list-style-type:circle;
  margin-left: 1.55rem;
  color: #cacaca;
}
.contact{
  min-width: 31.19rem;
  min-height: 25.14rem;
  /* border: 1px solid blue; */
}
.phn-num{
  color: #fff;
  font-weight: 300;
}
.env{
  margin-right: .25rem;
}

.copyright{
  /* border: 1px solid red; */
  width: 100%;
  min-height: 100px;
  background-color: #1E1E1E;
  display: flex;
  justify-content: center;
  align-items: center;
color: #cacaca;
}
.copyright .copy-contianer{
  text-align: center;
  color: #ADADAD;  
}


/* responsive styles */
@media only screen and (max-width:468px){
  .rightContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1em;
  }
  .explore{
   display: none;
  }
  .contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 0px;
    min-height: 0px;
  }
}

